<template>
  <b-img
    :src="appLogoImage"
    alt="logo"
    width="150"
  />
</template>
<script>
import {
  BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  data() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
}
</script>
